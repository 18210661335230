<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, selecione as empresas e os perfis associados ao usuário
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Empresa</th>
                <th class="text-left">Perfil</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="empresa in acessosLocal"
                :key="empresa.id"
              >
                <td>
                  {{ empresa.empresa }}
                </td>
                <td>
                  <select-user-profile
                    :label="null"
                    :selected="empresa.perfil"
                    @profile:selected="
                      ($event) => handlePerfilSelecionado($event, empresa)
                    "
                  />
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        min-width="0"
                        icon
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="removeEmpresa(empresa)"
                      >
                        <v-icon small>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2"></td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#2B60D6"
                        min-width="0"
                        icon
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="() => (dialogAddEmpresa = true)"
                      >
                        <v-icon small> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAddEmpresa"
      max-width="340px"
      eager
    >
      <v-card>
        <v-card-title class="headline"> Adicionar Empresa </v-card-title>
        <v-card-text>
          <v-row class="mx-auto">
            <select-company
              allCompanies
              @companySelected="handleEmpresaSelecionada($event)"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="triggerEmpresaSelecionada()"
          >
            Adicionar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click.stop="dialogAddEmpresa = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SecondTabContent',

  components: {
    SelectCompany: () => import('@/components/general/SelectCompany.vue'),
    SelectUserProfile: () =>
      import('@/components/general/SelectUserProfile.vue')
  },

  props: {
    acessos: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      dialogAddEmpresa: false,
      empresaSelecionada: null
    };
  },

  computed: {
    acessosLocal: {
      get() {
        return this.acessos;
      },
      set(newValue) {
        this.$emit('update:acessos', newValue);
      }
    }
  },

  methods: {
    handleEmpresaSelecionada(empresaSelecionada) {
      this.empresaSelecionada = empresaSelecionada;
    },

    triggerEmpresaSelecionada() {
      this.addEmpresa(this.empresaSelecionada);
      this.dialogAddEmpresa = false;
    },

    handlePerfilSelecionado(perfilSelecionado, empresaSelecionada) {
      let acessosLocal = [...this.acessosLocal];
      const index = this.acessosLocal.findIndex(
        (item) => item.id === empresaSelecionada.id
      );
      acessosLocal[index].perfil = perfilSelecionado;
      this.acessosLocal = acessosLocal;
    },

    addEmpresa(empresaSelecionada) {
      if (!empresaSelecionada) return;

      const empresaExiste = this.acessosLocal.filter(
        (item) => item.id === empresaSelecionada.id
      ).length;
      if (!empresaExiste) {
        this.acessosLocal.push({
          ...empresaSelecionada,
          empresa: empresaSelecionada.name,
          perfil: 'Comum'
        });
      } else {
        this.$toast.error(
          `A empresa ${empresaSelecionada.name} já está na lista de empresas selecionadas.`,
          '',
          { position: 'topRight' }
        );
      }
    },

    removeEmpresa(empresa) {
      let acessosLocal = [...this.acessosLocal];
      const index = this.acessosLocal.findIndex(
        (item) => item.id === empresa.id
      );
      acessosLocal.splice(index, 1);
      this.acessosLocal = acessosLocal;
    }
  }
};
</script>
